<template>

  <router-view :key="$route.fullPath"/>
<notifications position="bottom right" />
<LoadingIndicator />

</template>
<style>
  @import '@/assets/tabler.rtl.css';
</style>

<script>
import LoadingIndicator from '@/components/LoadingIndicator.vue';


export default {
  components: {
  LoadingIndicator,
},
    created() {
        this.$i18n.locale = 'ar' ;
    },
  };
    
</script>

<style>
[type="number"] {
    direction: rtl;
}
.flex-nowrap{
    justify-content: flex-end;
}

.dropdown-menu{
  left: 0;
}
</style>